<template>
  <intersect
    v-if="isShowProductsIntersect"
    @enter="productIntersect = 'Intersected'"
  >
    <section v-if="products" class="discount-catalog">
      <div class="container">
        <div class="header-block">
          <h2 class="title">{{ $t("discountCatalog") }}</h2>
          <router-link
            class="header-block__link"
            :to="{
              path: `/${$i18n.locale}/catalog?city_id=${$route.query.city_id}`,
            }"
            @click.native="gtmHandler"
          >
            {{ $t("showMore") }}
          </router-link>
        </div>
        <products-list
          v-if="products"
          :items="products"
          :from-list-name="$t('discountCatalog')"
        />
      </div>
    </section>
  </intersect>
</template>

<script>
import { mapState } from "vuex";
import { getAllProducts } from "@/api/products";
import Intersect from "vue-intersect";
import { eventBus } from "@/helpers";
export default {
  name: "DiscountList",
  components: {
    ProductsList: () => import("@/components/products/ProductsList.vue"),
    Intersect,
  },

  data() {
    return {
      products: null,
      isShowProductsIntersect: false,
      productIntersect: "",
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
  },
  watch: {
    selectedCity: {
      deep: true,
      handler() {
        getAllProducts(1, 5, this.selectedCity.id).then((response) => {
          this.products = response.data;
        });
      },
    },
    productIntersect: {
      handler() {
        if (this.productIntersect === "Intersected") {
          this.viewProducts(this.products);
        }
      },
    },
  },

  mounted() {
    if (this.selectedCity) {
      getAllProducts(1, 5, this.selectedCity?.id).then((response) => {
        this.products = response.data;
      });
    }

    setTimeout(() => {
      this.isShowProductsIntersect = true;
    }, 1000);
  },
  methods: {
    viewProducts(products) {
      eventBus.$emit("gtm", {
        event: "view_item_list",
        type: "view_item_list",
        listName: "блок Каталог скидок",
        items: products,
        category: "",
      });
    },
  },
};
</script>
